import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import commonFun from '../static/js/commonFun'
import axios from 'axios'
import {post,get,patch,put} from "../static/js/http.js"
import qs from 'qs'
import less from 'less'
Vue.use(less)
Vue.prototype.$post=post;
Vue.prototype.$get=get;
Vue.prototype.$patch=patch;
Vue.prototype.$put=put;
Vue.prototype.$commonFun = commonFun
Vue.prototype.$qs = qs
Vue.config.productionTip = false
Vue.use(ElementUI)

Vue.directive('loadmore', {
    bind(el, binding) {
      const selectWrap = el.querySelector('.el-table__body-wrapper')
      selectWrap.addEventListener('scroll', function() {
        let sign = 80
        const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight
        if (scrollDistance <= sign) {
          binding.value()
        }
      })
    }
  })

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer);

Viewer.setDefaults({
  Options: { "button": true, "navbar": false, "title": false, "toolbar": false, "tooltip": true, "movable": true, "zoomable": true, "rotatable": true, "scalable": true, "transition": true, "fullscreen": true, "keyboard": true, "url": "data-source" }
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
