<template>
	<div class="login-wrap">
		<div class="login-box">
			<div class="ms-title">实训室方案在线配置系统</div>
			<!--<div class="ms-title">西安志得网络</div>-->
			<div class="ms-login">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
					<el-form-item prop="username" label="用户名：">
						<el-input @keyup.enter.native="logins" v-model="ruleForm.username"></el-input>
					</el-form-item>
					<el-form-item prop="password" label="密码：">
						<el-input type="password" v-model="ruleForm.password" @keyup.enter.native="logins"></el-input>
					</el-form-item>
					<div class="login-btn">
						<el-button type="primary" @click="logins">登录</el-button>
					</div>
				</el-form>

			</div>

		</div>
		<a href="https://beian.miit.gov.cn" target=_blank
			style="position: absolute;bottom: 30px;left: 45%;color: #fff">备案号：陕ICP备17009726号-5</a>
	</div>
</template>

<script>
	export default {
		data: function() {
			return {
				ruleForm: {
					username: '',
					password: '',
				},
				rules: {
					username: [{
							required: true,
							message: '请输入用户名',
							trigger: 'blur'
						},
						//{ pattern: /^(\w){5,20}$/, message: '只能输入5-20个字母、数字、下划线' }
						//{ pattern: /^[\u4E00-\u9FA5]+$/, message: '用户名只能为中文' }
						//{ pattern:/^[a-zA-Z]w{4,17}$/, message: '以字母开头，长度在6-18之间， 只能包含字符、数字和下划线'}
					],
					password: [{
							required: true,
							message: '请输入密码',
							trigger: 'blur'
						},
						{
							min: 5,
							max: 30,
							message: '长度在 5 到 30 个字符'
						},
						{
							pattern: /^(\w){5,20}$/,
							message: '只能输入5-20个字母、数字、下划线'
						}
					]
				},
			}
		},
		created() {},
		methods: {
			logins() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.$post('login/userLogin', {
							userName: this.ruleForm.username,
							password: this.ruleForm.password
						}).then((res) => {
							let datas = res
							sessionStorage.setItem('username',datas.userName);
							sessionStorage.setItem('userId',datas.id);
							sessionStorage.setItem('roleId',datas.roleId);
							sessionStorage.setItem('Imgurl',datas.url);
							sessionStorage.setItem('userOnlyId',datas.userOnlyId);
							this.$router.push({path:'/Home'})
						})
					} else {
						
					}
				});
			},
		}
	}
</script>

<style scoped lang="less">
	.login-wrap {
		position: relative;
		width: 100vw;
		height: 100vh;
		background: #324157;
	}

	::-ms-clear,
	::-ms-reveal {
		display: none;
	}

	/*sogou_secure_inputs_container{display:none;}*/
	input::-webkit-input-safebox-button {
		display: none;
	}

	.login-box {
		text-align: center;
		position: absolute;
		width: 700px;
		height: 360px;
		left: 50%;
		top: 50%;
		margin-left: -350px;
		margin-top: -180px;

		.ms-title {
			text-align: center;
			font-size: 30px;
			color: #fff;
		}

		.ms-login {
			width: 75%;
			/*height: 195px;*/
			padding: 40px 30px;
			border-radius: 5px;
			background: #fff;
			display: inline-block;
			margin-top: 25px;

			.login-btn {
				text-align: center;
				margin-top: 65px;

				button {
					width: 100%;
					line-height: 20px;
					font-size: 16px;
				}
			}
		}

		.codes {
			text-align: center;
			/*height: 40px;*/
			cursor: pointer;
			width: 130px;
			border: 1px solid #e6e6e6;
			display: inline-block;
			letter-spacing: 9px;
			text-decoration: underline;
			color: #0074FF;
			text-shadow: 0 1px hsl(0, 0%, 85%), 0 2px hsl(0, 0%, 80%), 0 3px hsl(0, 0%, 75%), 0 4px hsl(0, 0%, 70%), 0 5px hsl(0, 0%, 65%), 0 5px 10px black;
			font-size: 21px;

			font-family: "Microsoft YaHei"
		}

		.forgetPwd {
			font-size: 14px;
			color: #00a0e9;
			text-decoration: underline;
			float: right;
		}

		.eyes {
			position: relative;

			.allEye {
				position: absolute;
				right: 15px;
				top: -9px;
			}

		}
	}
</style>
