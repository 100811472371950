<template>
	<div class="menuManage" id="menuManages" v-loading="loadings">

		<div class="navCommon">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>设备录入</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="menuBox">
			<el-button v-if="roleId==4" type="primary" icon="el-icon-plus" @click="$router.push({ path: 'page2'})">新增设备
			</el-button>
			<el-button v-if="roleId==4" type="primary" icon="el-icon-plus"
				@click="information = 3,editshow = true,uploadselect = ''">批量导入设备
			</el-button>

			<div class="search">
				<div class="left" v-if="fclassification&&fclassification.length>0">
					<!-- <el-cascader id="gffff" ref="gffff" placeholder="请选择" v-model="select" :options="fclassification"
						:props="{value:'id',label:'equipmentName',expandTrigger: 'hover'}">
					</el-cascader> -->
					<el-cascader @change="cascaderdatachange" :props="props" v-model="select"></el-cascader>
				</div>
				<div class="right">
					<el-input clearable @clear="inputclear" placeholder="请输入内容" v-model="input3"
						class="input-with-select">
						<!-- <el-select v-model="select" slot="prepend" placeholder="请选择">
							<el-option v-for="(item,index) in fclassification" :label="item.equipmentName"
								:value="item.id" :key="index">
							</el-option>
						</el-select> -->
						<el-button slot="append" icon="el-icon-search" @click="keywordclick">按名称</el-button>
						<el-button slot="append" icon="el-icon-search" @click="keywordbymanufacturerclick">按厂家
						</el-button>
					</el-input>
				</div>
			</div>
			<div class="menuList">
				<el-tabs type="border-card" @tab-click="findtableData" v-model="tabpaneindex">
					<el-tab-pane :label="item" :name="index+''" v-for="(item,index) in tabpanename">
						<el-table :height="610" v-loadmore="loadMore" border ref="multipleTable" :data="tableData"
							tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
							<el-table-column :selectable="checkSelectable" type="selection" width="55">
							</el-table-column>
							<el-table-column type="index" label="序号" width="50">
							</el-table-column>
							<el-table-column type="expand">
								<template slot-scope="props">
									<div>是否订做：{{ props.row.eqCustomMade }}</div>
									<div>发票、税：{{ props.row.eqInvoice }}</div>
									<div>是否安装/安装费：{{ props.row.eqInstall }}</div>
									<div>型号：{{ props.row.eqNorms }}</div>
									<div>技术参数：{{ props.row.eqSkill }}</div>
									<div>功能用途：{{ props.row.eqFunction }}</div>
									<!-- <div>品牌：{{ props.row.eqBrand }}</div>
									<div>单位：{{ props.row. eqUnit }}</div> -->
									<div v-if="props.row.eqCustom"
										v-for="(item,index) in JSON.parse(props.row.eqCustom)">
										{{item.name}}：{{ item.value }}
									</div>
								</template>
							</el-table-column>
							<el-table-column show-overflow-tooltip label="设备类型">
								<template slot-scope="props">
									{{props.row.shixunName}}>{{props.row.nextTypeName}}
								</template>
							</el-table-column>
							<el-table-column prop="eqName" label="名称">
							</el-table-column>
							<el-table-column width="300" prop="eqModel" show-overflow-tooltip label="规格">
							</el-table-column>
							<el-table-column width="80" show-overflow-tooltip prop="eqPurchaseRoute" label="购买途径">
								<template slot-scope="props">
									<template v-if="props.row.eqPurchaseRoute.slice(0,4) == 'http'">
										<a :href="props.row.eqPurchaseRoute" target="_blank">点击跳转</a>
									</template>
									<template v-else>
										{{props.row.eqPurchaseRoute}}
									</template>
								</template>
							</el-table-column>
							<!-- <el-table-column prop="eqOriginalPrice" show-overflow-tooltip>
					  		<template slot="header" slot-scope="scope">
					  			单价
					  			<template v-if="selectionshow">
					  				<i @click="information = 1,editForm.name ='',renovationdata()"
					  					class="aleft el-icon-edit"></i>
					  			</template>
					  		</template>
					  	</el-table-column> -->
							<el-table-column v-if="roleId == 4" width="100" prop="eqSalePrice" show-overflow-tooltip
								label="原始价格">
							</el-table-column>
							<el-table-column v-if="roleId == 4" width="100" prop="eqTimesRate" show-overflow-tooltip>
								<template slot="header" slot-scope="scope">
									倍率
									<template v-if="selectionshow">
										<i @click="inforloadings = false,information = 1,editForm.name ='',renovationdata()"
											class="aleft el-icon-edit"></i>
									</template>
								</template>
							</el-table-column>
							<el-table-column width="100" prop="eqOriginalPrice" show-overflow-tooltip label="售价">
							</el-table-column>
							<el-table-column show-overflow-tooltip>
								<template slot="header" slot-scope="scope">
									厂家信息(点击名称查看详情)
								</template>
								<template slot-scope="scope">
									<span style="cursor: pointer;"
										@click="eqFactoryinfoshow = true,eqFactoryinfo(scope.row.eqFactory)">{{scope.row.eqFactoryName}}</span>
								</template>
							</el-table-column>
							<el-table-column width="100" prop="eqBrand" show-overflow-tooltip label="品牌">
							</el-table-column>
							<el-table-column width="60" prop="eqUnit" show-overflow-tooltip label="单位">
							</el-table-column>
							<el-table-column width="120">
								<template slot="header" slot-scope="scope">
									操作
									<template v-if="selectionshow">
										<i class="aleft el-icon-delete" @click="multipledelete"></i>
									</template>
								</template>
								<template slot-scope="scope">
									<el-button :disabled="scope.row.userId == userId?false:true" size="mini"
										@click="information = 2,renovationdata(scope.row.id)" type="primary"
										icon="el-icon-edit">
									</el-button>
									<el-button :disabled="scope.row.userId == userId?false:true" size="mini"
										@click="singledelete(scope.row.id)" type="primary" icon="el-icon-delete">
									</el-button>
								</template>
							</el-table-column>
						</el-table>
						<div class="pagination" v-if="totalnumber>50">
							<el-pagination :page-size="50" :current-page="pagenum" @current-change="paginationchange"
								background layout="prev, pager, next" :total="totalnumber">
							</el-pagination>
						</div>
					</el-tab-pane>
					<!-- 
					<el-tab-pane label="中档商品">配置管理</el-tab-pane>
					<el-tab-pane label="低档商品">角色管理</el-tab-pane>
					<el-tab-pane label="其他">角色管理</el-tab-pane> -->
				</el-tabs>
			</div>
		</div>
		<el-dialog :title="information == 1 ? '批量修改' : information == 2 ? '验证码':'批量上传文件'" :visible.sync="editshow"
			width="60%" class="addform">
			<template v-if="information == 1">
				<el-form :model="editForm" :rules="editrules" ref="editruleForm">
					<el-form-item prop="name">
						<el-input @input="pricevalchang" v-model="editForm.name" type="number" step="0.1" :min="0">
						</el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="editshow = false">取 消</el-button>
					<el-button :disabled="inforloadings" type="primary" @click="editformdata('editruleForm')">确 定
					</el-button>
				</span>
			</template>
			<template v-if="information == 2">
				<el-input class="usinput" placeholder="13991278005" :disabled="true"></el-input>
				<el-input v-model="codevalue" placeholder="请输入验证码" class="input-with-select">
					<el-button slot="append" @click="vodetimes">{{vodetext}}</el-button>
				</el-input>
				<span slot="footer" class="dialog-footer">
					<el-button @click="editshow = false">取 消</el-button>
					<el-button type="primary" @click="verificationcode">确 定</el-button>
				</span>
			</template>
			<template v-if="information == 3">
				<el-button type="primary">
					<a class="xzmuban" :href="$commonFun.imgserverSrc +'upload/设备模板.xlsx'">下载模板</a>
				</el-button>
				<!-- <el-select v-model="uploadselect" placeholder="请选择上传分类">
					<el-option v-for="(item,index) in equipmentTypeList" :label="item.equipmentName" :value="item.id"
						:key="index">
					</el-option>
				</el-select> -->
				<!-- <el-cascader id="sadasdsa" ref="sadasdsa" placeholder="请选择" v-model="uploadselect" :options="cclassification"
					:props="{value:'id',label:'equipmentName',expandTrigger: 'hover'}">
				</el-cascader> -->
				<el-cascader @change="cascaderdatachange" :props="props2" v-model="uploadselect"></el-cascader>

				<div v-if="uploadselect" class="leadStuTable" @click.stop="addExcel" id="addExcel">选择文件
					<input type="file" id="fileExcel" @change="onFileExcelChange($event)"
						accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
						style="display: none;" />
					<i class="iconfont icon-add"></i>
				</div>
				<span class="important">*模板中的厂家ID请在厂家列表中查看并填写对应的厂家ID*</span>
				<span class="important">*模板中的技术参数最大字数为10000字（不包括空格、符号、字母）*</span>
				<!-- <span slot="footer" class="dialog-footer">
					<el-button @click="editshow = false">取 消</el-button>
					<el-button type="primary" @click="verificationcode">确 定</el-button>
				</span> -->
			</template>
		</el-dialog>
		<el-dialog :visible.sync="eqFactoryinfoshow" width="70%">
			<div class="infobody">
				<div class="infos">
					<span>厂家名称：</span><span>{{eqFactoryinfodata.ftyName? eqFactoryinfodata.ftyName: '无'}}</span>
				</div>
				<div class="infos">
					<span>厂址：</span><span>{{eqFactoryinfodata.ftyAddress? eqFactoryinfodata.ftyAddress: '无'}}</span>
				</div>
				<div class="infos">
					<span>电话：</span><span>{{eqFactoryinfodata.ftyTel? eqFactoryinfodata.ftyTel: '无'}}</span>
				</div>
				<div class="infos">
					<span>法人姓名：</span><span>{{eqFactoryinfodata.ftyLegalUser? eqFactoryinfodata.ftyLegalUser: '无'}}</span>
				</div>
				<div class="infos">
					<span>联系电话：</span><span>{{eqFactoryinfodata.ftyFiled4? eqFactoryinfodata.ftyFiled4: '无'}}</span>
				</div>
				<div class="infos">
					<span>产品类型：</span><span>{{eqFactoryinfodata.ftyFiled1? eqFactoryinfodata.ftyFiled1: '无'}}</span>
				</div>
				<div class="infos">
					<span>营业范围：</span><span>{{eqFactoryinfodata.ftyBusiness? eqFactoryinfodata.ftyBusiness: '无'}}</span>
				</div>
				<div class="infos">
					<span>公司简介：</span><span>{{eqFactoryinfodata.ftyContent? eqFactoryinfodata.ftyContent: '无'}}</span>
				</div>
				<div class="infos">
					<span>公司官网地址：</span><span>{{eqFactoryinfodata.ftyUrl? eqFactoryinfodata.ftyUrl: '无'}}</span>
				</div>
				<div v-if="eqFactoryinfodata.ftyCertificate"><span>营业执照</span>
					<span>
						<viewer :images="eqFactoryinfodata.ftyCertificate.split(',')">
							<el-carousel class="imgbanner" trigger="click" height="400px" :autoplay="false"
								arrow="always">
								<el-carousel-item v-for="(item,index) in eqFactoryinfodata.ftyCertificate.split(',')"
									:key="'pl'+index">
									<span class="bannerimg"><img :src="item" alt=""></span>
								</el-carousel-item>
							</el-carousel>
						</viewer>
					</span>
				</div>
				<div v-if="eqFactoryinfodata.ftyQualified"><span>合格证书</span>
					<span>
						<viewer :images="eqFactoryinfodata.ftyQualified.split(',')">
							<el-carousel class="imgbanner" trigger="click" height="400px" :autoplay="false"
								arrow="always">
								<el-carousel-item v-for="(item,index) in eqFactoryinfodata.ftyQualified.split(',')"
									:key="'pl'+index">
									<span class="bannerimg"><img :src="item" alt=""></span>
								</el-carousel-item>
							</el-carousel>
						</viewer>
					</span>
				</div>
				<div v-if="eqFactoryinfodata.ftyLogo"><span>公司logo</span>
					<span>
						<viewer :images="eqFactoryinfodata.ftyLogo.split(',')">
							<el-carousel class="imgbanner" trigger="click" height="400px" :autoplay="false"
								arrow="always">
								<el-carousel-item v-for="(item,index) in eqFactoryinfodata.ftyLogo.split(',')"
									:key="'pl'+index">
									<span class="bannerimg"><img :src="item" alt=""></span>
								</el-carousel-item>
							</el-carousel>
						</viewer>
					</span>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import $ from 'jquery'
	export default {
		name: "menu-management",
		data() {
			let _this = this;
			return {
				props: {
					value: 'id',
					label: 'equipmentName',
					lazy: true,
					lazyLoad(node, resolve) {
						console.log(node)
						setTimeout(() => {
							if (node.level == 0) {
								resolve(_this.fclassification);
							} else if (node.level == 1) {
								if (node.data.id > 1) {
									_this.$post('equipment/queryEquipmentNextType', {
										proId: node.data.id
									}).then((res) => {
										let datas = res
										if (res && res.length > 0) {
											res.unshift({
												id: 0,
												nextTypeName: '全部'
											})
										} else {
											datas = [{
												id: 0,
												nextTypeName: '全部'
											}]
										}
										const secondLevel = datas.map(val => ({
											id: val.id,
											equipmentName: val.nextTypeName,
											leaf: node.level >= 1
										}));
										resolve(secondLevel);
									})
								} else {
									resolve([])
								}
							} else {
								return false
							}
						}, 1000)

					}
				},
				props2: {
					value: 'id',
					label: 'equipmentName',
					lazy: true,
					lazyLoad(node, resolve) {
						setTimeout(() => {
							if (node.level == 0) {
								resolve(_this.cclassification);
							} else if (node.level == 1) {
								_this.$post('equipment/queryEquipmentNextType', {
									proId: node.data.id
								}).then((res) => {
									let datas = res
									if (!res || res.length <= 0) {
										datas = [{
											id: 0,
											nextTypeName: '无',
											disabled: true
										}]
									} else {
										datas.forEach((e, m) => {
											e.disabled = false
										})
									}
									const secondLevel = datas.map(val => ({
										id: val.id,
										equipmentName: val.nextTypeName,
										disabled: val.disabled,
										leaf: node.level >= 1
									}));
									resolve(secondLevel);
								})
							} else {
								return false
							}
						}, 1000)

					}
				},
				eqFactoryinfodata: '',
				eqFactoryinfoshow: false,
				grade: 1,
				tabpaneindex: '0',
				tabpanename: ['低档商品', '中档商品', '高档商品', '其他'],
				uploadselect: '',
				totalnumber: "",
				pagenum: 1,
				userId: sessionStorage.getItem('userId'),
				roleId: sessionStorage.getItem('roleId'),
				vodetext: '获取验证码',
				times: '',
				codevalue: '',
				selectionshow: false,
				information: false,
				editshow: false,
				loadings: false,
				inforloadings: false,
				input3: "",
				select: [],
				FormshixunId: '',
				cclassification: [],
				fclassification: [{
					id: 0,
					equipmentName: '全部',
					children: []
				}],
				tableData: [],
				routerid: '',
				editForm: {
					name: ''
				},
				editrules: {
					name: [{
						required: true,
						message: '请输入修改的值',
						trigger: 'blur'
					}],
				},
				batchModifyjsonStr: [],
				datalisttype: 0,
				equipmentlist: '',
				trainingtypedata: [{
					id: 0,
					equipmentName: '全部',
				}],
				copytype: 50,
				TypeListdata: []
			}
		},
		created() {
			this.select = [this.fclassification[0].id]
			this.getEquipmentTypeList()
			this.getEquipmentList()
		},
		computed: {

		},
		watch: {

		},
		mounted() {

		},
		methods: {
			loadMore() {
				var tableNum = this.tableData.length;
				let a = this.equipmentlist.slice(tableNum, tableNum + 50);
				if (a.length === 0) {
					// this.$message.warning('已经到底啦')
				}else{
					a.map(item => {
						this.tableData.push(item);
					});
				}
			},
			cascaderdatachange(val) {
				console.log(val)
			},
			//获取二级分类
			async gettrainingtype() {
				await this.$get('equipment/queryEquipmentNextType').then((res) => {
					if (res && res.length > 0) {
						this.TypeListdata = res
						res.forEach((e, m) => {
							e.equipmentName = e.nextTypeName
							this.trainingtypedata.push(e)
						})
					}
				})
				this.$nextTick(() => {

				})
				this.getEquipmentTypeList()
			},
			pricevalchang(val) {
				console.log(val)
				if (isNaN(val) || !val || parseFloat(val) < 0) {
					this.$message({
						type: 'warning',
						message: '请输入正确的数字!'
					});
					this.editForm.name = ''
				} else {
					this.editForm.name = parseFloat(val)
					// this.editForm.name = Math.abs(this.editForm.name)
				}
			},
			inputvaluechange(val) {
				console.log(val)
			},
			eqFactoryinfo(id) {
				this.$post('programme/selectByPrimaryKey', {
					id: id
				}).then((res) => {
					this.eqFactoryinfodata = res
				})
			},
			findtableData(val) {
				if (!this.input3) {
					this.datalisttype = 0
				}
				this.grade = parseInt(val.paneName) + 1
				this.pagenum = 1
				this.tableData = []
				if (this.datalisttype == 0) {
					this.getEquipmentList()
				} else if (this.datalisttype == 1) {
					this.keywordsearch()
				} else {
					this.keywordbymanufacturer()
				}

				this.$nextTick(() => {
					// this.loadings = false
				})
			},
			keywordbymanufacturerclick() {
				this.tabpaneindex = '0'
				this.datalisttype = 2
				this.grade = 1
				this.pagenum = 1
				if (this.input3) {
					this.keywordbymanufacturer()
				} else {
					// this.FormshixunId = this.select[0]
					this.getEquipmentList()
				}
			},
			checkSelectable(row) {
				return row.isModify == '否'
			},
			async keywordbymanufacturer() {
				if (this.input3) {
					this.loadings = true
					this.tableData = []
					this.totalnumber = 0
					await this.$post('equipment/getEquipmentListByFacturer', {
						shixunId: this.select[0],
						nextTypeId: this.select[1],
						userId: this.userId,
						eqManufacturer: this.input3,
						roleId: this.roleId,
						pagenum: this.pagenum,
						pagesize: 50,
						filed4: this.grade
					}).then((res) => {
						console.log(res)
						this.loadings = false
						this.equipmentlist = res
						res.forEach((e, m) => {
							if (e.userId == this.userId) {
								e.isModify = '否'
							} else {
								e.isModify = '是'
							}
						})
						if (res.length > 50) {
							this.tableData = res.slice(0, 50)
						} else {
							this.tableData = res
						}
						this.equipmentlist = res
						this.FormshixunId = this.select[0]
						this.totalnumber = res.total
					}).catch((err) => {
						this.tableData = []
						this.loadings = false
					})
					this.$set(this.tableData)
				} else {
					this.FormshixunId = this.select[0]
					this.getEquipmentList()
					// this.$message({
					// 	type: 'warning',
					// 	message: '请输入关键字!'
					// });
				}
			},
			//清除搜索
			inputclear() {
				this.tabpaneindex = '0'
				this.datalisttype = 0
				this.grade = 1
				this.pagenum = 1
				this.select = [this.fclassification[0].id]
				this.getEquipmentList()
			},
			//分页
			paginationchange(val) {
				this.pagenum = val
				if (this.datalisttype == 0) {
					this.getEquipmentList()
				} else if (this.datalisttype == 2) {
					this.keywordbymanufacturer()
				} else {
					this.keywordsearch()
				}

			},
			keywordclick() {
				this.tabpaneindex = '0'
				this.datalisttype = 1
				this.grade = 1
				this.pagenum = 1
				if (this.input3) {
					this.keywordsearch()
				} else {
					// this.FormshixunId = this.select
					this.getEquipmentList()
					// this.$message({
					// 	type: 'warning',
					// 	message: '请输入关键字!'
					// });
				}
			},
			//检索
			async keywordsearch() {
				this.loadings = true
				this.tableData = []
				this.totalnumber = 0
				await this.$post('equipment/getEquipmentListByName', {
					shixunId: this.select[0],
					nextTypeId: this.select[1] ? this.select[1] : 0,
					userId: this.userId,
					name: this.input3,
					roleId: this.roleId,
					filed4: this.grade
				}).then((res) => {
					this.loadings = false
					this.equipmentlist = res
					res.forEach((e, m) => {
						if (e.userId == this.userId) {
							e.isModify = '否'
						} else {
							e.isModify = '是'
						}
					})
					if (res.length > 50) {
						this.tableData = res.slice(0, 50)
					} else {
						this.tableData = res
					}
					this.equipmentlist = res
				}).catch((err) => {
					this.tableData = []
					this.loadings = false
				})
				this.$set(this.tableData)
			},
			//切换设备类型
			shixunIdchange(val) {
				this.pagenum = 1
				this.input3 = ""
				this.select = [0, 1]
				this.getEquipmentList()
			},
			//获取设备类型
			async getEquipmentTypeList() {
				this.cclassification = []
				await this.$post('equipment/getEquipmentTypeList').then((res) => {
					if (res && res.length > 0) {
						res.forEach((e, m) => {
							this.fclassification.push(e)
						})
						this.cclassification = res
					}
				})
				this.$nextTick(() => {

				})
			},
			//设备获取
			async getEquipmentList() {
				this.loadings = true
				this.tableData = []
				this.equipmentlist = []
				this.totalnumber = 0
				await this.$post('equipment/queryEquipmentList', {
					proId: this.select[0],
					nextTypeId: this.select[1] ? this.select[1] : 0,
					filed4: this.grade
				}).then((res) => {
					// console.log(res)
					this.loadings = false
					res.forEach((e, m) => {
						if (e.userId == this.userId) {
							e.isModify = '否'
						} else {
							e.isModify = '是'
						}
					})
					if (res.length > 50) {
						this.tableData = res.slice(0, 50)
					} else {
						this.tableData = res
					}
					this.equipmentlist = res
					this.totalnumber = res.total
				}).catch((err) => {
					this.loadings = false
				})
				this.$nextTick(() => {
					// this.tabpaneindex = '0'
					// this.select = [this.fclassification[0].id,this.fclassification[0].children[0].id]
					// this.uploadselect = ''
				})
				this.$set(this.tableData)
			},
			//单个删除设备
			singledelete(id) {
				this.$confirm('此操作将删除该设备, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.delEquipment(id)
				}).catch(() => {

				});
			},
			//多个删除设备
			multipledelete() {
				this.$confirm('此操作将删除所有选中的设备, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.delEquipment(this.batchModifyjsonStr.join(','))
				}).catch(() => {

				});
			},
			//调用删除接口
			async delEquipment(data) {
				await this.$post('equipment/delEquipmentById', {
					id: data
				}).then((res) => {
					if (res) {
						this.tabpaneindex = '0'
						this.datalisttype = 0
						this.grade = 1
						this.pagenum = 1
						// this.select = this.fclassification[0].id
						this.FormshixunId = this.fclassification[0].id
						this.getEquipmentList()
						this.$message({
							type: 'success',
							message: '删除成功'
						});
					} else {
						this.$message({
							type: 'warning',
							message: '删除失败'
						});
					}
				})
			},
			//验证码倒计时
			vodetimes() {
				this.vodetext = 60
				this.times = setInterval(() => {
					if (this.vodetext == 0) {
						clearInterval(this.times);
						this.vodetext = "获取验证码"
					} else {
						this.vodetext--
					}
				}, 1000);
				this.$post('sendCode', {
					// tel: "13991278005"
					tel: "13991278005"
				}).then((res) => {
					this.$message({
						message: '验证码已发送至13991278005请注意查收',
						type: 'success'
					});
					console.log(res)
				})
			},
			//获取验证码
			verificationcode() {
				if (!this.codevalue) {
					this.$message({
						message: '请输入验证码',
						type: 'warning'
					});
				} else {
					this.$post('querenCode', {
						tel: "13991278005",
						code: this.codevalue
					}).then((res) => {
						sessionStorage.setItem('smscode', this.codevalue);
						if (this.selectionshow) {
							this.information = 1
						} else {
							this.$router.push({
								path: "page2",
								query: {
									id: this.routerid
								}
							})
						}

					})
				}
			},
			//修改时先获取验证码
			renovationdata(id) {
				console.log(id)
				console.log(sessionStorage.getItem('smscode'))
				if (id) {
					this.routerid = id
				}
				if (id && !sessionStorage.getItem('smscode')) {
					this.editshow = true
				} else if (id && sessionStorage.getItem('smscode')) {
					this.$router.push({
						path: "page2",
						query: {
							id: id
						}
					})
				} else {
					this.editshow = true
				}

			},
			//选择的多个设备
			handleSelectionChange(val) {
				console.log(val)
				this.batchModifyjsonStr = []
				if (val.length >= 2) {
					val.forEach((e, m) => {
						// this.batchModifyjsonStr.push(e.id)
						this.batchModifyjsonStr.push({
							id: e.id,
							timesRate: e.eqTimesRate,
							updatePrice: e.eqSalePrice,
							filed1: e.filed1,
							filed2: e.filed2
						})
					})
					this.selectionshow = true
				} else {
					this.selectionshow = false
				}
				console.log(this.batchModifyjsonStr)
			},
			//批量修改原始单价
			editformdata(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (!this.editForm.name || isNaN(this.editForm.name) || parseFloat(this.editForm.name) <
							0) {
							this.$message({
								message: '请输入正确的数字!',
								type: 'warning'
							});
						} else {
							this.inforloadings = true
							console.log(this.batchModifyjsonStr)
							let ghh = []
							this.batchModifyjsonStr.forEach((e, m) => {
								let ghd = ''
								if (e.filed2) {
									ghd = JSON.parse(e.filed2)
									ghd.push({
										lable: parseFloat(e.filed1) + 1,
										value: this.editForm.name
									})
								} else {
									ghd = [{
										lable: 1,
										value: this.editForm.name
									}]
								}
								ghh.push({
									id: e.id,
									eqTimesRate: this.editForm.name,
									eqSalePrice: (parseFloat(this.editForm.name) * parseFloat(e
											.updatePrice)).toString().indexOf('.') != -1 ? (
											parseFloat(
												this.editForm.name) * parseFloat(e.updatePrice))
										.toFixed(
											2) : (parseFloat(this.editForm.name) * parseFloat(e
											.updatePrice)),
									filed1: e.filed1 ? parseFloat(e.filed1) + 1 : 1,
									filed2: JSON.stringify(ghd)
								})
							})
							console.log(ghh)
							this.$post('equipment/equipmentBatchModify', {
								jsonStr: JSON.stringify(ghh),
								// updatePrice: this.editForm.name
							}).then(() => {
								this.$message({
									message: '修改成功',
									type: 'success'
								});
								this.inforloadings = false
								this.getEquipmentList()
								this.editshow = false
							})

						}
					} else {

					}
				});
			},
			//重置表单
			toggleSelection(rows) {
				this.$refs.multipleTable.clearSelection();
			},
			addExcel() {
				$('#addExcel').find($('input[type=file]')).trigger('click')
				return false
			},
			onFileExcelChange(e, zyId) {
				// 检验ppt文件
				// console.log(e)
				// 判断是否IE浏览器
				var isIE = /msie/i.test(navigator.userAgent) && !window.opera;
				// console.log(isIE)

				var fileSize = 0;
				// var filetypes = [".xlsx", ".xls"];
				var filetypes = [".xlsx"];
				// var filetypes = [".xls"];
				var filepath = e.target.value;

				if (isIE && !e.target.files) {
					var filePath = e.target.value;
					var fileSystem = new ActiveXObject("Scripting.FileSystemObject");
					if (!fileSystem.FileExists(filePath)) {
						this.$alert('只支持.xlsx文件！', '提示', {
							confirmButtonText: '确定',
						});
						return false;
					}
					var file = fileSystem.GetFile(filePath);
					fileSize = file.Size;
				} else {
					fileSize = e.target.files[0].size;
				}

				if (filepath) {
					var isnext = false;
					var fileend = filepath.substring(filepath.indexOf("."));
					if (filetypes && filetypes.length > 0) {
						for (var i = 0; i < filetypes.length; i++) {
							if (filetypes[i] == fileend) {
								isnext = true;
								break;
							}
						}
					}
					if (!isnext) {
						this.$alert('不接受此文件类型,且文件名中不能出现"."符号！', '提示', {
							confirmButtonText: '确定',
						});
						e.target.value = "";
						return false;
					} else {
						this.sendExcelFileApi()
					}

				} else {
					return false;
				}
			},
			sendExcelFileApi(id) {
				var File = $("#fileExcel")[0].files[0];
				// console.log(File)
				if (File != undefined) {
					var params = new FormData();
					params.append("file", File)
					params.append("userId", this.userId)
					params.append("type", this.uploadselect[0])
					params.append("nextTypeId", this.uploadselect[1])
					var _this = this
					// $.ajax({
					// 	type: "POST",
					// 	url: _this.$commonFun.serverSrc + "equipment/importEquipment",
					// 	data: params,
					// 	xhrFields: {
					// 		withCredentials: true
					// 	},
					// 	crossDomain: true,
					// 	processData: false, //必须false才会自动加上正确的Content-Type
					// 	contentType: false,
					// 	success: function(msg) {
					// 		console.log(msg)
					// 	},
					// 	error: function(msg) {
					// 		 this.$message.error('班级上传失败');
					// 	}
					// });
					this.editshow = false
					this.$post('equipment/importEquipment', params).then((res) => {
						this.$message({
							message: '导入成功',
							type: 'success'
						});
						// this.pagenum = 1
						// this.FormshixunId = this.uploadselect
						this.uploadselect = ''
						this.getEquipmentList()
					})
				}
				$("#fileExcel").val(''); // 当第二次点击同一个文件，由于value值没有发生变化，无法触发change/onchange事件。因此清空input的value值。
			},
		}
	}
</script>

<style lang="less" scoped>
	@import "../../static/css/base.less";

	.infobody {
		height: 65vh;
		overflow-y: auto;

		.infos {
			margin-bottom: 20px;

			>span {
				font-size: 16px;
				color: #999;
			}

			>:first-child {
				font-size: 18px;
				color: #666;
			}
		}
	}

	.bannerimg {
		display: block;
		position: relative;
		width: 100%;
		height: 100%;

		>img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.menuManage .el-select-dropdown {
		width: 52%;
	}


	.menuManage {
		.el-scrollbar {
			ul {
				overflow: hidden;

				li {
					float: left;

					i {
						font-size: 20px;
					}
				}
			}

			ul.el-select-dropdown__list {
				overflow: hidden;

			}
		}

		.tips {
			font-size: 14px;
			color: red;
			margin: 10px;

			span {
				color: red;
				display: inline-block;
				margin: 0 6px;
			}
		}

		.menuBox {
			margin-top: 40px;

			.menuList {
				margin-top: 40px;
			}
		}

		.addMenu {
			width: 60%;
			margin-left: 20%;
			margin-top: 100px;
			background: #ffffff;

			h3 {
				border-bottom: solid 1px #cccccc;
				padding: 15px;
				font-size: 18px;
				font-weight: bold;
			}

			.addList {
				padding: 15px;

				.btn {
					text-align: center;
					margin-top: 20px;
				}

				.menuIcon {
					.el-input {
						width: 200px;
					}
				}
			}
		}

		.layer {
			background: rgba(3, 3, 3, 0.8);
			height: calc(~"100vh");
			width: calc(~"100vw");

			position: fixed;
			z-index: 10;
			top: 0;
			left: 0;

			i {
				position: absolute;
				top: 5px;
				right: 30px;
				z-index: 20;
				font-size: 30px;
				color: #ffffff;
			}
		}

		.pagination {
			text-align: center;
			margin: 40px 0;
		}

		.important {
			color: #ea3d1a;
			font-size: 20px;
			display: block;
			margin: 40px 0 20px 0;
		}
	}

	.search {
		>div {
			display: inline-block;
			vertical-align: middle;
		}

		>:last-child {
			width: 75%;
		}
	}
</style>
<style>
	.menuManage .search {
		width: 70%;
		margin: 20px 0;
	}

	.search .el-input-group__prepend {
		width: 20%;
	}

	.search .el-select {
		width: 100%;
	}

	.menuList .aleft {
		margin-left: 20px;
		cursor: pointer;
	}

	.menuList .aleft2 {
		margin-left: 15px;
		cursor: pointer;
	}

	.leadStuTable {
		display: inline-block;
		padding: 8px 25px;
		border: 1px solid #409EFF;
		color: #fff;
		background-color: #409EFF;
		cursor: pointer;
		border-radius: 50px;
		margin-left: 20%;
		font-size: 16px;
		/* margin-bottom: 50px; */
	}

	.mbbutton {
		margin-right: 20px;
	}

	.xzmuban {
		color: #fff;
		text-decoration: none;

	}
</style>
<style>
	.usinput {
		margin-bottom: 30px;
	}

	.addform .el-button--primary {
		margin-right: 10%;
	}

	.el-tooltip__popper.is-dark {
		max-width: 80%;
	}
</style>
