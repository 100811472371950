import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import page1 from '../views/page1.vue'
import page2 from '../views/page2.vue'
import page3 from '../views/page2.vue'
import fgty from '../views/fgty.vue'
import routers from '../components/routers.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Login',
		component: Login
	},
	{
		path: '/fgty',
		name: 'fgty',
		component: fgty
	},
	{
		path: '/Home',
		component: Home,
		children: [{
			path: "",
			redirect: "page1"
		}, {
			path: 'page1',
			component: page1
		}, {
			path: 'page2',
			component: page2
		}, {
			path: 'page3',
			component: () => import('@/views/page3.vue'),
		}, {
			path: 'addprogramme',
			component: () => import('@/views/addprogramme.vue'),
		}, {
			path: 'page4',
			component: () => import('@/views/page4.vue'),
		}, {
			path: 'datamanagement',
			component: () => import('@/views/datamanagement.vue'),
		}, {
			path: 'datamodify',
			component: () => import('@/views/datamodify.vue'),
		}, {
			path: 'addDemandScheme',
			component: () => import('@/views/addDemandScheme.vue'),
		}, {
			path: 'customerlist',
			component: () => import('@/views/customerlist.vue'),
		}, {
			path: 'customerdetails',
			component: () => import('@/views/customerdetails.vue'),
		}, {
			path: 'addBudgetProgramme',
			component: () => import('@/views/addBudgetProgramme.vue'),
		}, {
			path: 'budgetProgramme',
			component: () => import('@/views/budgetProgramme.vue'),
		}, {
			path: 'addnewuser',
			component: () => import('@/views/addnewuser.vue'),
		}, {
			path: 'manufactorlist',
			component: () => import('@/views/manufactorlist.vue'),
		}, {
			path: 'addmanufactor',
			component: () => import('@/views/addmanufactor.vue'),
		}, {
			path: 'examinelist',
			component: () => import('@/views/examinelist.vue'),
		}]
	},
]

const router = new VueRouter({
	routes
})
router.beforeEach((to,form,next)=>{
	if(sessionStorage.getItem('userId')||!sessionStorage.getItem('userId')&&to.name=='Login'){
		next()
	}else{
		next({name:'Login'})
	}
})

export default router
