<template>
	<div class="form">
		<el-breadcrumb separator-class="el-icon-arrow-right" class="headermunt">
			<el-breadcrumb-item :to="{ path: '/Home' }">设备录入</el-breadcrumb-item>
			<el-breadcrumb-item>新增设备</el-breadcrumb-item>
		</el-breadcrumb>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
			<el-form-item label="类型" prop="shixuntypeId">
				<el-cascader @change="cascaderchange" placeholder="请选择设备类型" :props="props" v-model="ruleForm.shixuntypeId"  ></el-cascader>
			</el-form-item>
			<el-form-item label="名称" prop="eqName">
				<el-input v-model="ruleForm.eqName"></el-input>
			</el-form-item>
			<el-form-item label="规格" prop="eqModel">
				<el-input v-model="ruleForm.eqModel"></el-input>
			</el-form-item>
			<el-form-item label="型号" prop="eqNorms">
				<el-input v-model="ruleForm.eqNorms"></el-input>
			</el-form-item>
			<el-form-item label="技术参数" prop="eqSkill">
				<el-input v-model="ruleForm.eqSkill"></el-input>
			</el-form-item>
			<el-form-item label="品牌" prop="eqBrand">
				<el-input v-model="ruleForm.eqBrand"></el-input>
			</el-form-item>
			<el-form-item label="功能用途" prop="eqFunction">
				<el-input v-model="ruleForm.eqFunction"></el-input>
			</el-form-item>
			<el-form-item label="单位" prop="eqUnit">
				<el-input v-model="ruleForm.eqUnit"></el-input>
			</el-form-item>
			<el-form-item label="厂家" prop="eqFactory">
				<el-select v-model="ruleForm.eqFactory" filterable placeholder="请选择">
					<el-option v-for="item in manufactorlist" :key="item.id" :label="item.ftyName" :value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="购买途径" prop="eqPurchaseRoute">
				<el-input v-model="ruleForm.eqPurchaseRoute"></el-input>
			</el-form-item>
			<el-form-item label="是否订做" prop="eqCustomMade">
				<el-radio-group v-model="ruleForm.eqCustomMade">
					<el-radio label="是"></el-radio>
					<el-radio label="否"></el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="物品档次" prop="filed4">
				<el-radio-group v-model="ruleForm.filed4">
					<el-radio :label="1">低</el-radio>
					<el-radio :label="2">中</el-radio>
					<el-radio :label="3">高</el-radio>
					<el-radio :label="4">通用</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="发票、税" prop="eqInvoice">
				<el-input v-model="ruleForm.eqInvoice"></el-input>
			</el-form-item>
			<el-form-item label="是否安装/安装费" prop="eqInstall">
				<el-input v-model="ruleForm.eqInstall"></el-input>
			</el-form-item>
			<el-form-item label="原始单价" prop="eqSalePrice">
				<el-input @input="magnificationchange" type="number" :min="0" v-model="ruleForm.eqSalePrice"></el-input>
			</el-form-item>
			<el-form-item label="调整倍率">
				<el-input @input="magnificationchange" type="number" step="0.1" :min="0" v-model="ruleForm.eqTimesRate">
				</el-input>
			</el-form-item>
			<el-form-item label="出厂单价">
				{{ruleForm.eqOriginalPrice}}
				<!-- <el-input @change="factoryprice" type="number" :min="0" v-model="ruleForm.factoryprice"></el-input> -->
			</el-form-item>
			<el-form-item v-for="(item,index) in Formdata" :label="item.name" :key="'a'+index">
				<el-input v-model="item.value"></el-input>
				<el-button @click="deleteadddata(index)" class="addbutton" type="primary" size="mini"
					icon="el-icon-delete">删除</el-button>
			</el-form-item>
		</el-form>
		<div class="addbuttons">
			<el-button @click="datashow = true,addForm.parametername = '',addForm.parametervalue = ''" type="primary"
				icon="el-icon-plus">自定义参数</el-button>
		</div>
		<div class="formsubmit">
			<el-button type="primary" @click="createEquipment">提交</el-button>
			<el-button @click="$router.go(-1)">取消</el-button>
		</div>
		<el-dialog title="添加参数" :visible.sync="datashow" width="40%" class="addform">
			<el-form :model="addForm" :rules="addrules" ref="addruleForm" class="addforms">
				<el-form-item label="参数名" prop="parametername">
					<el-input v-model="addForm.parametername"></el-input>
				</el-form-item>
				<el-form-item label="参数值" prop="parametervalue">
					<el-input v-model="addForm.parametervalue"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="datashow = false">取 消</el-button>
				<el-button type="primary" @click="addformdata('addruleForm')">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			let _this = this
			return {
				props: {
					value: 'id',
					label: 'equipmentName',
					lazy: true,
					lazyLoad(node, resolve) {
						console.log(node)
						setTimeout(() => {
							if (node.level == 0) {
								resolve(_this.cclassification);
							} else if (node.level == 1) {
								_this.$post('equipment/queryEquipmentNextType', {
									proId: node.data.id
								}).then((res) => {
									let datas = res
									if(!res||res.length<=0){
										datas = [{
											id:0,
											nextTypeName:'无',
											disabled:true
										}]
									}else{
										datas.forEach((e,m)=>{
											e.disabled = false
										})
									}
									const secondLevel = datas.map(val => ({
										id: val.id,
										equipmentName: val.nextTypeName,
										disabled: val.disabled,
										leaf: node.level >= 1
									}));
									resolve(secondLevel);
								})
							} else {
								return false
							}
						}, 1000)
				
					}
				},
				manufactorlist: [],
				manufactorvalue: "",
				userId: sessionStorage.getItem('userId'),
				roleId: sessionStorage.getItem('roleId'),
				parametername: '',
				parametervalue: '',
				datashow: false,
				equipmentTypeList: [],
				ruleForm: {
					shixuntypeId: [],
					eqName: "",
					eqModel: "",
					eqFactory: "",
					eqPurchaseRoute: "",
					eqCustomMade: "",
					filed4: "",
					eqInvoice: "",
					eqInstall: "",
					eqOriginalPrice: "",
					eqNorms: "",
					eqSkill: "",
					eqBrand: "",
					eqFunction: "",
					eqUnit: "",
					eqTimesRate: '1',
					eqSalePrice: '',
					filed1: 1,
					filed2: [{
						lable: '',
						value: ''
					}]
				},
				Formdata: [],
				addForm: {
					parametername: "",
					parametervalue: ""
				},
				rules: {
					shixuntypeId: [{
						required: true,
						message: '请选择设备类型',
						trigger: 'change'
					}],
					eqName: [{
						required: true,
						message: '请输入设备名称',
						trigger: 'blur'
					}],
					eqModel: [{
						required: true,
						message: '请输入设备规格',
						trigger: 'blur'
					}],
					eqFactory: [{
						required: true,
						message: '请选择厂家',
						trigger: 'change',
					}],
					eqPurchaseRoute: [{
						required: true,
						message: '请输入购买途径',
						trigger: 'blur'
					}],
					eqNorms: [{
						required: true,
						message: '请输入型号',
						trigger: 'blur'
					}],
					eqSkill: [{
						required: true,
						message: '请输入技术参数',
						trigger: 'blur'
					}],
					eqBrand: [{
						required: true,
						message: '请输入品牌',
						trigger: 'blur'
					}],
					eqFunction: [{
						required: true,
						message: '请输入功能用途',
						trigger: 'blur'
					}],
					eqUnit: [{
						required: true,
						message: '请输入单位',
						trigger: 'blur'
					}],
					eqCustomMade: [{
						required: true,
						message: '请选择是否订做',
						trigger: 'change'
					}],
					filed4: [{
						required: true,
						message: '请选择物品档次',
						trigger: 'change'
					}],
					eqInvoice: [{
						required: true,
						message: '请输入发票、税',
						trigger: 'blur'
					}],
					eqInstall: [{
						required: true,
						message: '请输入是否安装/安装费',
						trigger: 'blur'
					}],
					eqSalePrice: [{
						required: true,
						message: '请输入原始单价',
						trigger: 'blur'
					}],
				},
				addrules: {
					parametername: [{
						required: true,
						message: '请输入参数名',
						trigger: 'blur'
					}],
					parametervalue: [{
						required: true,
						message: '请输入参数值',
						trigger: 'blur'
					}],
				},
				copydata: '',
				trainingtypedata: []
			};
		},
		mounted() {
			// this.times = setInterval(() => {
			// 	console.log("12")
			// }, 300000);
			// this.$once('hook:beforeDestroy', () => {
			// 	clearInterval(this.times);
			// }) 
			if (this.$route.query.id) {
				this.getEquipment()
			}
			this.getEquipmentTypeList()
			this.getAllFactory()
			// this.gettrainingtype()
		},
		methods: {
			cascaderchange(val){
				console.log(val)
			},
			//获取二级分类
			async gettrainingtype() {
				await this.$get('equipment/queryEquipmentNextType').then((res) => {
					// console.log(trainingtypedata)
					// console.log(res)
					if (res && res.length > 0) {
						res.forEach((e, m) => {
							e.equipmentName = e.nextTypeName
						})
					}
					this.trainingtypedata = res
				})
				this.getEquipmentTypeList()
			},
			//原始单价改变时
			eqOriginalPricechange(val) {
				console.log(val)
				console.log(this.ruleForm.eqSalePrice)
			},
			//倍率改变时
			magnificationchange(val) {
				console.log(val)
				if(isNaN(val)||!val||parseFloat(val)<0){
					this.ruleForm.eqTimesRate = 1
				}else{
					this.ruleForm.eqOriginalPrice = this.ruleForm.eqSalePrice * this.ruleForm.eqTimesRate
					this.ruleForm.eqOriginalPrice.toString().indexOf(".") == -1 ? '' : this.ruleForm.eqOriginalPrice = this
						.ruleForm.eqOriginalPrice.toFixed(2)
				}
				
			},
			//获取厂家列表
			getAllFactory() {
				this.$get('programme/getAllFactory').then((res) => {
					this.manufactorlist = res
				})
			},
			//获取设备详情
			getEquipment() {
				this.$post('equipment/getEquipmentById', {
					id: this.$route.query.id
				}).then((res) => {
					this.copydata = res
					this.ruleForm.shixuntypeId = [res.shixunId,res.equipmentNextTypeId]
					this.ruleForm.eqName = res.eqName
					this.ruleForm.eqModel = res.eqModel
					this.ruleForm.eqFactory = res.eqFactory
					this.ruleForm.eqPurchaseRoute = res.eqPurchaseRoute
					this.ruleForm.eqCustomMade = res.eqCustomMade
					this.ruleForm.eqInvoice = res.eqInvoice
					this.ruleForm.eqInstall = res.eqInstall
					this.ruleForm.eqSalePrice = res.eqSalePrice
					this.ruleForm.filed4 = res.filed4
					this.ruleForm.eqSkill = res.eqSkill
					this.ruleForm.eqBrand = res.eqBrand
					this.ruleForm.eqNorms = res.eqNorms
					this.ruleForm.eqFunction = res.eqFunction
					this.ruleForm.eqUnit = res.eqUnit
					this.ruleForm.eqTimesRate = parseFloat(res.eqTimesRate)
					this.ruleForm.eqOriginalPrice = parseFloat(res.eqOriginalPrice) ? parseFloat(res
						.eqOriginalPrice) : res.eqTimesRate * res.eqSalePrice
					this.ruleForm.filed1 = res.filed1
					if (this.ruleForm.filed2) {
						this.ruleForm.filed2 = JSON.parse(res.filed2)
					} else {
						this.ruleForm.filed2 = []
					}
					if (res.eqCustom) {
						this.Formdata = JSON.parse(res.eqCustom)
					}
					console.log(this.ruleForm)
				})
				this.$nextTick(() => {
					this.ruleForm.eqSalePrice.toString().indexOf(".") == -1 ? '' : this.ruleForm.eqSalePrice = this
						.ruleForm.eqSalePrice.toFixed(2)
				})
			},
			//获取设备类型
			async getEquipmentTypeList() {
				this.cclassification = []
				await this.$post('equipment/getEquipmentTypeList').then((res) => {
					if (res && res.length > 0) {
						this.cclassification = res
					}
				})
				this.$nextTick(() => {
			
				})
			},
			//新增设备
			createEquipment() {
				console.log(this.ruleForm)
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						// console.log(this.ruleForm.versionList)
						this.ruleForm.equipmentNextTypeId = this.ruleForm.shixuntypeId[1]
						this.ruleForm.shixunId = this.ruleForm.shixuntypeId[0]
						if (this.Formdata && this.Formdata.length > 0) {
							this.ruleForm.eqCustom = JSON.stringify(this.Formdata)
						}
						if (this.$route.query.id) {
							this.ruleForm.id = this.$route.query.id
							this.ruleForm.filed2.push({
								lable: parseFloat(this.copydata.filed1) + 1,
								value: this.ruleForm.eqTimesRate
							})
							this.ruleForm.filed1 = parseFloat(this.copydata.filed1) + 1
						} else {
							this.ruleForm.filed2 = [{
								lable: 1,
								value: this.ruleForm.eqTimesRate
							}]
							this.ruleForm.filed1 = 1
						}
						this.ruleForm.filed2 = JSON.stringify(this.ruleForm.filed2)
						this.ruleForm.userId = this.userId
						Reflect.deleteProperty(this.ruleForm, 'shixuntypeId')
						console.log(this.ruleForm)
						this.$post('equipment/createEquipment', this.ruleForm).then((res) => {
							// console.log(res)
							this.$message({
								message: this.$route.query.id ? "修改成功" : "新增成功",
								type: 'success'
							});
							this.$router.push({
								path: 'page1'
							})
						})
					} else {

					}
				});
			},
			addformdata(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.Formdata.push({
							name: this.addForm.parametername,
							value: this.addForm.parametervalue
						})
						this.datashow = false
					} else {

					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			deleteadddata(index) {
				this.Formdata.splice(index, 1)
			}
		}
	}
</script>

<style scoped lang="less">
	.form {
		.addbutton {
			margin-left: 10%;
		}

		.headermunt {
			margin: 0 0 40px 0;
		}

		.formsubmit {
			text-align: center;
			margin-top: 200px;
		}
	}

	.addbuttons {
		padding: 40px 0 0 0;
		text-align: center;
	}
</style>
<style>
	.addforms .el-input {
		width: 50%;
	}

	.addforms .el-input {
		width: 60%;
	}
</style>
