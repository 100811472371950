import commonFun from '../../static/js/commonFun.js'
import axios from 'axios';
import QS from 'qs'
import {
	MessageBox
} from 'element-ui';
import {
	Message
} from 'element-ui';
import {
	Notification
} from 'element-ui';
import router from '@/router'
let _this = this
// let userinfo = localStorage.getItem('userinfo');
// let token = ''
// if(userinfo){
//     token = localStorage.getItem('userinfo').token;
// }
axios.defaults.timeout = 10000 // 请求超时
const devBaseUrl = commonFun.serverSrc;
const proBaseUrl = '/apis';
let url;
process.env.NODE_ENV === 'development' ? url = proBaseUrl : url = devBaseUrl;
axios.defaults.baseURL = url;


//http request 拦截器
axios.interceptors.request.use(
	config => {
		if(config.data instanceof FormData){
			config.headers = {
				 headers:{'Content-Type':'multipart/form-data'}
			}
		}else{
			config.headers = {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
			config.data = QS.stringify(config.data)
		}
		return config;
	},
	error => {
		return Promise.reject(err);
	}
);


//http response 拦截器
axios.interceptors.response.use(
	response => {
		if (response.status == 200) {
			// if (response.data.code == -1) {
			// 	//未登录
			// 	MessageBox.confirm('请先登录', '提示', {
			// 		confirmButtonText: '确定',
			// 		cancelButtonText: '取消',
			// 		type: 'warning'
			// 	}).then(() => {
			// 		router.push('/')
			// 	}).catch(() => {
			// 		Message({
			// 			title: '提示',
			// 			message: '请尽快登录获得更好体验',
			// 			type: 'warning'
			// 		});
			// 	})
			// 	return Promise.reject(response.data)
			// } else if (response.data.code == 0) {
			// 	//请求失败
			// 	Notification.warning({
			// 		title:"提示",
			// 		message:response.data.msg
			// 	})
			// 	return Promise.reject(response.data)

			// } else if (response.data.code == 1) {
			// 	//请求成功
			// 	return response.data;
			// }
			return response.data;
		} else {
			alert("请求出错")
			return Promise.reject(response.data)
		}

	},
	error => {
		return Promise.reject(error)
	}
)


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}) {
	return new Promise((resolve, reject) => {
		axios.get(url, {
				params: params
			})
			.then(response => {
				if (response.code == 200) {
					resolve(response.data);
				} else {
					Notification.warning({
						duration:1000,
						title: "提示",
						message: response.infomessage
					})
				}
			})
			.catch(err => {
				reject(err)
			})
	})
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data) {
	return new Promise((resolve, reject) => {
		axios.post(url, data)
			.then(response => {
				if (response.code == 200) {
					resolve(response.data);
				} else {
					Notification.warning({
						duration:1000,
						title: "提示",
						message: response.infomessage
					})
					reject(response);
				}
			}, err => {
				reject(err)
			})
	})
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
	return new Promise((resolve, reject) => {
		axios.patch(url, data)
			.then(response => {
				resolve(response.data);
			}, err => {
				reject(err)
			})
	})
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
	return new Promise((resolve, reject) => {
		axios.put(url, data)
			.then(response => {
				resolve(response.data);
			}, err => {
				reject(err)
			})
	})
}
export function verificationtoken() {
	axios.post("/api/user/index", QS.stringify({
		platform: "web"
	})).then((res) => {
		console.log(res)
	})
}
