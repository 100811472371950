<template>
	<div class="home">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				
			}
		},
		mounted() {
			
		},
		methods: {
			
		}
	}
</script>
<style scoped="scoped" lang="less">
	
</style>
