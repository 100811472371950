<template>
	<div class="header">
		<div class="logo">实训室方案在线配置系统</div>
		<div class="user-info">
			<el-dropdown trigger="click" @command="handleCommand">
				<span class="el-dropdown-link">
					<i class="icon iconfont icon-yonghutouxiang topUser"></i>
					<i class="icon iconfont icon-tubiaozhizuo- topXiaoxi"></i>
					{{name}}
					<span class="el-icon-caret-bottom"></span>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="loginout">退出</el-dropdown-item>
					<template v-if="roleId!=1">
					<el-dropdown-item command="imgclick">下载分享码</el-dropdown-item>
					<el-tooltip :content="$commonFun.imgserverSrc+'dist1/reception/dist/index.html#/?'+userOnlyId" placement="bottom" effect="light">
					  <el-dropdown-item >
					  	<a :href="$commonFun.imgserverSrc+'dist1/reception/dist/index.html#/?'+userOnlyId" target="_blank">分享链接</a>
					  	<!-- <a :href="$commonFun.imgserverSrc+'#/?'+userOnlyId" target="_blank">分享链接</a> -->
					  </el-dropdown-item>
					</el-tooltip>
					</template>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				name: sessionStorage.getItem('username'),
				Imgurl: sessionStorage.getItem('Imgurl'),
				userOnlyId: sessionStorage.getItem('userOnlyId'),
				roleId: sessionStorage.getItem('roleId'),
				isNew: '',
			}
		},
		computed: {

		},
		created() {

		},
		methods: {
			handleCommand(command) {
				if (command == 'loginout') {
					//退出登录
					sessionStorage.clear()
					this.$router.push('/');
				}
				if (command == 'imgclick') {
					var alink = document.createElement("a");
					  alink.href = this.Imgurl;
					  alink.target = "_blank"
					  alink.download = "分享码.jpg"; //图片名
					  alink.click();
				}
			}
		}
	}
</script>
<style scoped>
	.numicon{
		float: left;
		margin: 20px 30px 0 10px;
		display: inline-block;
		color: #fff;
		font-size: 35px;
	}
	.header {
		background-color: #3a75af;
		position: relative;
		box-sizing: border-box;
		width: 100%;
		height: 70px;
		font-size: 22px;
		line-height: 70px;
		color: #fff;
	}

	.header .logo {
		float: left;
		width: 250px;
		text-align: center;
	}

	.user-info {
		float: right;
		padding-right: 50px;
		font-size: 16px;
		color: #fff;
	}

	.user-info .el-dropdown-link {
		position: relative;
		display: inline-block;
		padding-left: 50px;
		color: #fff;
		cursor: pointer;
		vertical-align: middle;
	}

	.user-info .user-logo {
		position: absolute;
		left: 0;
		top: 15px;
		width: 40px;
		height: 40px;
		border-radius: 50%;
	}

	.el-dropdown-menu__item {
		text-align: center;
	}

	.topUser {
		font-size: 21px;
		margin-right: 10px;
	}

	.topXiaoxi {
		color: red;
		font-size: 22px;
		position: absolute;
		right: 60px;
		top: -10px;
	}

	.fkXx {
		position: absolute;
		left: 69px;
		top: 41px;
		font-size: 22px;
		color: red;
	}
</style>
