<template>
	<div class="home">
		<headers></headers>
		<div class="menu" ref="dsaw">
			<el-menu ref="saewq" :default-active="$route.path" router text-color="#fff" background-color="#336799"
				:unique-opened="true" class="el-menu-vertical-demo">
				<el-menu-item index="/Home/page1"><img class="icones" src="../../static/img/adf3.png">设备管理
				</el-menu-item>
				<el-menu-item index="/Home/page3"><img class="icones" src="../../static/img/adf2.png">方案管理
				</el-menu-item>
				<el-menu-item index="/Home/customerlist"><img class="icones" src="../../static/img/adf5.png">客户列表
				</el-menu-item>
				<el-menu-item index="/Home/manufactorlist"><img class="icones" src="../../static/img/adf6.png">厂家列表
				</el-menu-item>
				<el-menu-item v-if="roleId == 1 || roleId == 3" index="/Home/examinelist"><img class="icones"
						src="../../static/img/adf7.png">审核列表</el-menu-item>
				<template v-if="roleId == 1">
					<el-menu-item index="/Home/addnewuser"><img class="icones" src="../../static/img/usersadd.png">人员管理
					</el-menu-item>
					<el-menu-item index="/Home/datamanagement"><img class="icones" src="../../static/img/adf4.png">数据管理
					</el-menu-item>
					<el-menu-item index="/Home/page4"><img class="icones" src="../../static/img/adf1.png">方案配置
					</el-menu-item>
					<!-- <el-menu-item index="/Home/budgetProgramme">预算方案配置</el-menu-item> -->
				</template>
			</el-menu>
			
		</div>
		<div class="childer" id="childer" ref="csawq">
			<el-backtop target=".childer" :bottom="100"></el-backtop>
			<router-view></router-view>
		</div>
		<span class="municon" @click="arrowclick($event)"><i class="arrow el-icon-arrow-left"></i></span>
	</div>
</template>

<script>
	import headers from "../components/Header.vue"
	export default {
		name: 'Home',
		components: {
			headers
		},
		data() {
			return {
				roleId: sessionStorage.getItem('roleId'),
				dsada:true,
				screenWidth:''
			}
		},
		watch: {
			screenWidth: function(val, oldVal) {
				if(val>15){
				let _this = this
				let data = val-15
				_this.childerwidth =data- _this.$refs.dsaw.offsetWidth
				// console.log(_this.childerwidth)
				_this.$refs.csawq.style.width = _this.childerwidth+'px'
				// console.log(data)
				// console.log(_this.$refs.dsaw.offsetWidth)
				}
			}
		
		},
		mounted() {
			this.screenWidth = document.documentElement.clientWidth
			this.childerwidth =(this.screenWidth-15)-this.$refs.dsaw.offsetWidth
			console.log(this.screenWidth)
			console.log(this.childerwidth)
			window.onresize = () => {
			
				return (() => {
			
					this.screenWidth = document.documentElement.clientWidth
			
				})()
			
			}
		},
		methods: {
			arrowclick(e){
				if (this.dsada) {
					this.$refs.dsaw.style.transform = 'translate(-250px,0)'
					// this.$refs.csawq.style.transform='translate(0,0)'
					this.$refs.csawq.style.width = '99%'
					e.target.style.transform = 'rotate(180deg)'
					e.currentTarget.style.transform = 'translate(0px,0)'
					e.currentTarget.style.left = '0'
					this.dsada = false
				} else {
					// console.log(this.childerwidth)
					this.$refs.dsaw.style.transform = 'translate(0,0)'
					// this.$refs.csawq.style.transform='translate(250px,0)'
					this.$refs.csawq.style.width = this.childerwidth+'px'
					e.target.style.transform = 'rotate(0deg)'
					e.currentTarget.style.transform = 'translate(250px,0)'
					e.currentTarget.style.left = '0'
					this.dsada = true
				}
			}
		}
	}
</script>
<style scoped="scoped" lang="less">
	.home {
		width: 100%;
		height: 100%;

		// position: relative;
		.municon {
			transition: all .5s;
			cursor: pointer;
			width: 21px;
			height: 60px;
			border-radius: 0 10px 10px 0;
			background-color: #336799;
			position: absolute;
			z-index: 1;
			transform: translate(250px, 0);
			top: 50%;
			// transform: translate(-50%, -50%);
			display: inline-block;
			text-align: center;

			.arrow {
				display: inline-block;
				line-height: 60px;
				color: #fff;
				font-size: 20px;
			}
		}

		.header {}

		.menu {
			opacity: 1;
			transition: all .5s;
			display: block;
			position: absolute;
			width: 250px;
			left: 0;
			top: 70px;
			bottom: 0;
			background: #336799;
			overflow-y: auto;
		}

		.childer {
			transition: all .5s;
			position: absolute;
			right: 0;
			top: 70px;
			padding: 20px;
			bottom: 0;
			width: 86%;
			// transform: translate(10px, 0);
			// left: 250px;
			box-sizing: border-box;
			height: 92vh;
			overflow-y: scroll; // 滚动样式
		}

		.icones {
			width: 25px;
			margin: 0 30px 0 0;
		}
	}

	.home:hover {}
</style>
<style>
	.menu .el-menu {
		border: none;
	}

	.menu .el-submenu__title,
	.menu .el-menu-item.is-active,
	.menu .el-menu-item {
		font-size: 18px;
	}
</style>
